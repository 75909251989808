@import '../../styles/variables.scss';

.Dashboard {
  $headerHeight: 5rem;
  $buttonWidth: 25rem;
  $buttonHeight: 25rem;

  .Dashboard__header {
    flex-shrink: 0;
    width: 100%;
    height: $headerHeight;
    background-color: $color-gray-1;
    border-bottom: 1px solid $color-gray-2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .Dashboard__header__logo {
    flex-grow: 1;
    height: 100%;
    padding: 0.8rem;
    user-select: none;

    svg {
      height: 100%;
    }
  }

  .Dashboard__container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;

    .Dashboard__button {
      width: $buttonWidth;
      height: $buttonHeight;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid $color-gray-5;
      border-radius: $border-radius-button;
      padding: 0;
      margin: 0.5rem;
      outline: 0;
      cursor: pointer;
      
      i {
        color: $color-gray-10;
        font-size: 4rem;

        + .text {
          margin-top: 1rem;
        }
      }

      .text {
        color: $color-gray-10;
        font-size: 2rem;

        &.time {
          font-size: $font-size-regular;
          margin-bottom: 1rem;
        }
      }

      .videoThumbnail {
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &:hover {
        border-color: $color-primary;

        i, .text {
          color: $color-primary;
        }
      }
    }
  }
}