$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif  ;
$font-family-tagline: 'Barlow', sans-serif;

$color-gray-0: #000000;
$color-gray-1: #111111;
$color-gray-2: #222222;
$color-gray-3: #333333;
$color-gray-4: #444444;
$color-gray-5: #555555;
$color-gray-6: #666666;
$color-gray-7: #777777;
$color-gray-8: #888888;
$color-gray-9: #999999;
$color-gray-10: #aaaaaa;
$color-gray-11: #bbbbbb;
$color-gray-12: #cccccc;
$color-gray-13: #dddddd;
$color-gray-14: #eeeeee;
$color-gray-15: #ffffff;

$color-coolgray-0: #000011;
$color-coolgray-1: #111122;
$color-coolgray-2: #222233;
$color-coolgray-3: #333344;
$color-coolgray-4: #444455;
$color-coolgray-5: #555566;
$color-coolgray-6: #666677;
$color-coolgray-7: #777788;
$color-coolgray-8: #888899;
$color-coolgray-9: #9999aa;
$color-coolgray-10: #aaaabb;
$color-coolgray-11: #bbbbcc;
$color-coolgray-12: #ccccdd;
$color-coolgray-13: #ddddee;
$color-coolgray-14: #eeeeff;
$color-coolgray-15: #ffffff;

$color-bluegray-9: #9999aa;
$color-brand-blue: #070063;
$color-brand-green: #2bcf82;
$color-brand-red: #DF4F70;
$color-brand-orange: #FF9900;

$color-primary: $color-brand-orange;
$color-default: $color-gray-15;
$color-warning: #FFCC00;
$color-danger: #FF4400;
$color-info: $color-brand-green;

.color-primary { color: $color-primary !important; }
.color-default { color: $color-default !important; }
.color-info { color: $color-info !important; }
.color-warning { color: $color-warning !important; }
.color-danger { color: $color-danger !important; }
.color-dimmed { color: $color-gray-9 !important; }

$transition-duration: 0.3s;
$opacity-dimmed: 0.5;
$border-radius: 0.8rem;
$border-radius-button: $border-radius * 0.5;
$border-radius-input: 0.3rem;
$footer-height: 7rem;
$install-prompt-height: 5rem;

$font-size-regular: 1.4rem;
$font-size-small: 1.2rem;
$font-size-smaller: 1.1rem;
$font-size-large: 1.6rem;
$font-size-larger: 1.8rem;
$font-size-h1: 3rem;
$font-size-h2: 2.5rem;
$font-size-button-icon: 1.5rem;
$font-size-button-icon-big: 2.2rem;
$font-size-input: 1.5rem;

.text-large { font-size: $font-size-large; }
.text-larger { font-size: $font-size-larger; }

@mixin animatedPlayButtonSize($size: 5rem, $color: $color-gray-15) {
  .AnimatedPlayButton {
    .iconContainer {
      margin-left: $size * 0.1;
      margin-top: $size * 0.05;

      &::after {
        width: $size;
        height: $size;
        margin-left: $size * -0.25;
        margin-top: $size * -0.25;
        border-width: $size * 0.1;
        border-color: $color;
      }

      i {
        font-size: $size * 0.7;
        margin-left: $size * 0.1;
        margin-top: $size * 0.05;
        color: $color;
      }
    }
  }
}

@mixin cornerButton($corner, $hitAreaCirc: 10rem) {
  // &::after {
  //   content: '';
  //   position: fixed;
  //   background: transparent;
  //   width: $hitAreaCirc;
  //   height: $hitAreaCirc;
  //   z-index: 1;

  //   @if $corner == tl {
  //     top: $hitAreaCirc * -0.5;
  //     left: $hitAreaCirc * -0.5;
  //     border-bottom-right-radius: 50%;
  //   } @else if $corner == tr {
  //     top: $hitAreaCirc * -0.5;
  //     right: $hitAreaCirc * -0.5;
  //     border-bottom-left-radius: 50%;
  //   } @else if $corner == bl {
  //     bottom: $hitAreaCirc * -0.5;
  //     left: $hitAreaCirc * -0.5;
  //     border-top-right-radius: 50%;
  //   } @else if $corner == br {
  //     bottom: $hitAreaCirc * -0.5;
  //     right: $hitAreaCirc * -0.5;
  //     border-top-left-radius: 50%;
  //   }
  // }

  // @if $corner == tl {
  //   padding-left: 2rem;
  // } @else if $corner == tr {
  //   padding-right: 2rem;
  // } @else if $corner == bl {
  //   padding-left: 2rem;
  // } @else if $corner == br {
  //   padding-right: 2rem;
  // }
}