@import './styles/variables.scss';

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
  -webkit-text-size-adjust: 100%;
}

@supports (-webkit-touch-callout: none) {
  html {
    font-size: 11px;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.AppWrapper,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  font-size: 16px; /* px fallback */
  font-size: 1.6rem; /* default font-size for document */
  line-height: 1.5; /* a nice line-height */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overscroll-behavior: none !important;
  box-sizing: border-box;
}

#root {
  background-color: $color-gray-0;
  padding: calc(env(safe-area-inset-top) - 20px) env(safe-area-inset-right) calc(env(safe-area-inset-bottom) - 20px) env(safe-area-inset-left);
}

.AppWrapper {
  position: relative;
}

* {
  &::-webkit-scrollbar-track {
    background-color: $color-gray-4;
  }

  &::-webkit-scrollbar {
      width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: $border-radius-input;
      background-color: $color-gray-9;
  }
}